<script lang="ts">import DailyReport from "./DailyReport.svelte";
import { auth, db } from "./firebase";
import FirebaseLogin from "./FirebaseLogin.svelte";
import Graph from "./Graph.svelte";
import Logs from "./Logs.svelte";
import Modules from "./Modules.svelte";
import Requests from "./Requests.svelte";
import Response from "./Response.svelte";
import Send from "./Send.svelte";
import Cal from './Cal.svelte';
let user;
let route = "";
auth.onAuthStateChanged(res => {
    if (!res)
        return (user = null);
    db.collection("facilitators")
        .doc(res.uid)
        .get()
        .then(doc => {
        const uid = doc.id;
        user = Object.assign(Object.assign({}, doc.data()), { uid });
    });
});
const backHandler = () => (route = "");
</script>

<header>
  <a href="https://aawaaz.co"> <img width="43" src="/Logo.png" alt="Logo" /></a>
  <h1 id="title">Adhyayan HN Portal</h1>
</header>
<main>
  {#if user === undefined}
    <h2>Loading...</h2>
  {:else if user}
    {#if !route}
      <h2>Hola, {user.name}</h2>
      <button on:click={() => (route = 'logs')}>Registros</button>
      <button on:click={() => (route = 'dailyReport')}>Reporte Diario</button>
      {#if user.role === 'ADMIN'}
        <button on:click={() => (route = 'modules')}>Modules</button>
        <button on:click={() => (route = 'cal')}>Calendar</button>
        <button on:click={() => (route = 'send')}>Send</button>
        <button on:click={() => (route = 'responses')}>Recuento de respuestas</button>
      {/if}
      <button on:click={() => (route = 'requests')}>Peticiones</button>
      {#if user.role !== 'TEACHER'}
        <button on:click={() => (route = 'graph')}>Gráficos de Respuestas</button>
      {/if}
      <button on:click={() => auth.signOut()}>Cerrar sesión</button>
    {:else}
      <button on:click={backHandler}>← Regresa</button>
      {#if route === 'logs'}
        <Logs {user} />
      {:else if route === 'dailyReport'}
        <DailyReport {user} />
      {:else if route === 'modules'}
        <Modules />
      {:else if route === 'cal'}
        <Cal />
      {:else if route === 'send'}
        <Send {user} />
      {:else if route === 'responses'}
        <Response />
      {:else if route === 'requests'}
        <Requests {user} />
      {:else if route === 'graph'}
        <Graph {user} />
      {/if}
    {/if}
  {:else}
    <FirebaseLogin />
  {/if}
</main>
<footer>© <a href="https://aawaaz.co">Aawaaz Education Services</a></footer>

<style>
  button {
    margin-bottom: 0.83rem;
  }
</style>
