<script lang="ts">import axios from "axios";
import { onMount } from "svelte";
import { db } from "./firebase";
import { collectionData } from "rxfire/firestore";
import { startWith } from "rxjs/operators";
;
export let user;
const customGroups = [
    "SHH-3",
    "SHH-4",
    "SHH-5",
    "SHH-6",
    "SHH-T",
    "WV-3",
    "WV-4",
    "WV-5",
    "WV-6",
    "CRS-3",
    "CRS-4",
    "CRS-5",
    "CRS-6",
];
let value;
let msgType;
let field;
let text;
let modules = [];
let module;
let group = "";
let msg;
let error;
let tp;
let showConfirm = false;
let disabled = false;
let logs;
onMount(() => {
    const query = db
        .collection("logs")
        .where("type", "==", "MODULE START")
        .orderBy("timestamp", "desc")
        .limit(15);
    logs = collectionData(query, "id").pipe(startWith([]));
    db.collection("moduleStats")
        .orderBy("module")
        .get()
        .then(qs => {
        modules = qs.docs.map(doc => doc.data());
    });
});
const sendCustom = () => {
    msg = error = "";
    let data = {
        field,
        text,
    };
    if (!(field === "all"))
        data = Object.assign(Object.assign({}, data), { value });
    disabled = true;
    axios
        .post("https://us-central1-aawaaz-adhyayan.cloudfunctions.net/custom", data, {
        headers: {
            uid: user.uid,
        },
    })
        .then(res => {
        disabled = false;
        msg = `Successfully sent`;
    })
        .catch(() => {
        disabled = false;
        error = `Error`;
    });
    value = "";
};
const sendModule = () => {
    msg = error = "";
    disabled = true;
    axios
        .post("https://us-central1-aawaaz-adhyayan.cloudfunctions.net/start", {
        module: module.module,
        field: "group",
        value: group,
        tp,
    }, {
        headers: {
            uid: user.uid,
        },
    })
        .then(() => {
        disabled = false;
        msg = `Successfully sent`;
    })
        .catch(() => {
        disabled = false;
        error = `Error`;
    });
    showConfirm = false;
};
</script>

<h2>Send</h2>
<label>
  Message Type:
  <select bind:value={msgType}>
    <option value="custom">Custom</option>
    <option value="module">Module</option>
  </select>
</label>

{#if msgType === 'custom'}
  <label>
    Recipient Type:
    <select bind:value={field} on:blur={() => (value = '')}>
      <option value="">Select an option</option>
      <option value="all">All</option>
      <option value="phone">Phone</option>
      <option value="group">Group</option>
    </select>
  </label>
  {#if field === 'group'}
    <label>
      Group:
      <select bind:value>
        <option value="">Select an option</option>
        {#each customGroups as groupInstance}
          <option>{groupInstance}</option>
        {/each}
      </select>
    </label>
  {:else if field === 'phone'}
    <label> Phone (without +504): <input bind:value /> </label>
  {/if}
  <label> Message: <textarea rows="4" cols="50" bind:value={text} /> </label>
  <button
    on:click={sendCustom}
    disabled={!text || !field || (!(field === 'all') && !value) || disabled}>Send</button>
{:else if msgType === 'module'}
  <label>
    Modules:
    <select bind:value={module} on:blur={() => (group = '')}>
      {#each modules as moduleOption}
        <option value={moduleOption}>{moduleOption.module}</option>
      {/each}
    </select>
  </label>
  <label>
    Group:
    <select bind:value={group}>
      <option value="">Select a group</option>
      {#if module}
        {#each module.groups as groupOption}
          <option>{groupOption}</option>
        {/each}
      {/if}
    </select>
  </label>
  <label>
    All/Tigo/Claro:
    <select bind:value={tp}>
      <option value="">Select an option</option>
      <option value="all">All</option>
      <option value="T">Tigo</option>
      <option value="C">Claro</option>
    </select>
  </label>
  {#if !showConfirm}
    <button
      on:click={() => (showConfirm = true)}
      disabled={disabled || !group || !tp}>Send</button>
  {:else}
    <button on:click={() => (showConfirm = false)}>Cancel</button><br />
    <button on:click={sendModule}>Click to Confirm</button>
  {/if}
{/if}
{#if disabled}
  <p>processing</p>
{:else if error}
  <p>{error}</p>
{:else if msg}
  <p>{msg}</p>
{/if}
<h2>Registros</h2>
{#if logs}
  <table>
    <thead>
      <tr>
        <th>Type</th>
        <th>Module</th>
        <th>Target</th>
        <th>Time</th>
      </tr>
    </thead>
    <tbody>
      {#each $logs as log (log.id)}
        <tr>
          <td>{log.type}</td>
          <td>{log.module}</td>
          <td>{log.target}</td>
          <td>{log.timestamp.toDate().toLocaleString()}</td>
        </tr>
      {/each}
    </tbody>
  </table>
{:else}
  <p>Ningún registro para mostrar</p>
{/if}<br />

<style>
  label {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
  }
  select {
    margin-left: 20px;
  }
  input {
    margin-left: 20px;
  }
  textarea {
    margin-left: 20px;
    resize: none;
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    td:nth-of-type(1):before {
      content: "Type";
    }
    td:nth-of-type(2):before {
      content: "Module";
    }
    td:nth-of-type(3):before {
      content: "Group";
    }
  }
</style>
