<script lang="ts">import { onMount } from "svelte";
import { db } from "./firebase";
let modules = [];
let texts = [];
let module;
onMount(() => {
    db.collection("moduleStats")
        .orderBy("module")
        .get()
        .then(qs => {
        modules = qs.docs.map(doc => doc.data());
    });
});
const getTexts = () => {
    if (!module) {
        texts = [];
    }
    db.collection("texts")
        .where("module", "==", module.module)
        .orderBy("code")
        .get()
        .then(qs => {
        texts = qs.docs.map(doc => doc.data());
    });
};
</script>

<h2>Modules</h2>
{#if modules.length}
  <label>
    Select a module:
    <select bind:value={module} on:change={getTexts} on:blur={getTexts}>
      <option value="">Select your option</option>
      {#each modules as moduleI (moduleI.module)}
        <option value={moduleI}>{moduleI.module}</option>
      {/each}
    </select>
  </label>
{:else}
  <p>No modules to show</p>
{/if}
{#if module}
  <ul>
    <li>Lesson Name: {module.lessonName}</li>
    <li>Grade: {module.grade}</li>
    <li>Subject: {module.subject}</li>
    <li>No. of questions: {module.questions}</li>
    <li>Groups: {module.groups}</li>
  </ul>
{/if}
{#if texts.length}
  <table>
    <thead>
      <tr>
        <th>Code</th>
        <th>Text</th>
        <th>Info</th>
        <th>First</th>
        <th>Last</th>
        <th>Next</th>
      </tr>
    </thead>
    <tbody>
      {#each texts as text (text.code)}
        <tr>
          <td>{text.code}</td>
          <td>{text.text}</td>
          <td>{text.info}</td>
          <td>{text.first}</td>
          <td>{text.last}</td>
          <td>{JSON.stringify(text.next)}</td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}

<style>
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    td:nth-of-type(1):before {
      content: "Code";
    }
    td:nth-of-type(2):before {
      content: "Text";
    }
    td:nth-of-type(3):before {
      content: "Info";
    }
    td:nth-of-type(4):before {
      content: "First";
    }
    td:nth-of-type(5):before {
      content: "Last";
    }
    td:nth-of-type(6):before {
      content: "Next";
    }
  }
</style>
